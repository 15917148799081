<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-20">
            <TeachersSelect
              v-model="filters.teacher_id"
              all-option="All"
            />
          </div>
          <div class="md-layout-item md-size-20">
            <PeriodsSelect
              v-model="filters.class_schedule_id"
              all-option="All"
            />
          </div>
          <div class="md-layout-item  md-size-20">
            <CoursesSelect
              v-model="filters.course_id"
              all-option="All"
            />
          </div>
          <div class="md-layout-item  md-size-20">
            <LevelsSelect
              v-model="filters.level_id"
              all-option="All"
            />
          </div>
          <div class="md-layout-item  md-size-20">
            <FormSelect
              v-model="filters.is_active"
              label="Active Status"
              :options="[
                { id: 'all', name: 'All' },
                { id: 1, name: 'Active' },
                { id: 0, name: 'Inactive' },
              ]"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success d-block md-sm w-100"
              @click="$refs.vtable.init()"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout my-3">
          <div class="md-layout-item">
            <md-button
              class="md-primary md-just-icon md-round float-right"
              @click="newClassroom(false)"
              @click.middle="newClassroom(true)"
            >
              <md-icon>
                add
              </md-icon>
              <md-tooltip>New Classroom</md-tooltip>
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <div class="alert alert-info">
              Column Pre-Enrolled, shows the number of students Pre-Enrolled for this week
            </div>
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getClassrooms"
              @onShowDetails="onShowDetails"
              @onActivate="onActivate"
              @onDeactivate="onDeactivate"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import moment from 'moment';
import {
  TeachersSelect,
  PeriodsSelect,
  LevelsSelect,
  CoursesSelect,
} from '@/components/Inputs/selects';
import { FormSelect } from '@/components/Inputs';

export default {
  components: {
    VueTable,
    TeachersSelect,
    PeriodsSelect,
    LevelsSelect,
    CoursesSelect,
    FormSelect,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'classroom_code',
            mask: 'class',
            sortable: true,
          },
          {
            title: 'classroom_name.name',
            mask: 'name',
            sortable: true,
          },
          {
            title: 'course.title',
            mask: 'course',
            sortable: true,
          },
          {
            title: 'course_level.name',
            mask: 'level',
            sortable: true,
          },
          {
            title: 'teacher.name',
            mask: 'teacher',
            sort_value: 'teacher.first_name',
            sortable: true,
          },
          {
            title: 'class_schedule.period',
            mask: 'period',
            sortable: true,
          },
          {
            title: 'max_students',
            mask: 'max. students',
            sortable: true,
          },
          {
            title: 'enrolments',
            sortable: true,
          },
          {
            title: 'holidays',
            mask: 'holidays',
            sortable: true,
          },
          {
            title: 'pre_enrolled',
            mask: 'pre-enrolled',
            sortable: true,
          },
          {
            title: 'is_active',
            mask: 'active',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
        ],
        actions: [
          {
            buttonClass: 'md-success',
            tooltip: 'Activate Classroom',
            callback: 'onActivate',
            icon: 'thumb_up_alt',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Deactivate Classroom',
            callback: 'onDeactivate',
            icon: 'thumb_down_alt',
          },

          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
        ],
        values: {},
      },
      newDate: moment().format(this.$store.getters['school_settings/settings'].date_format),
      showNewDate: false,
      filters: {
        teacher_id: 'all',
        class_schedule_id: 'all',
        level_id: 'all',
        course_id: 'all',
        is_active: 1,
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getClassrooms(params) {
      this.request(this.$API.CLASSROOMS.paginableUrl(params), 'get', this.filters, ({ data }) => {
        const aux = data;
        aux.data = data.data.map((x) => {
          // Activating buttons depending status
          const a = x;
          if (x.is_active) {
            a.onActivate = false;
          } else {
            a.onDeactivate = false;
          }
          return a;
        });
        this.vTable.values = aux;
      });
    },

    newClassroom(isMiddleClick) {
      this.openLink({ name: 'NewClassroom' }, isMiddleClick);
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({ name: 'ClassroomDetails', params: { id: item.classroom_id } }, isMiddleClick);
    },
    onActivate(item) {
      this.changeActiveStatus({
        ...item,
        is_active: true,
      });
    },
    onDeactivate(item) {
      this.changeActiveStatus({
        ...item,
        is_active: false,
      });
    },
    changeActiveStatus(item) {
      this.request(this.$API.CLASSROOMS + item.classroom_id, 'put', item, () => {
        this.fireSuccess('Classroom updated succesfully');
        this.$refs.vtable.init();
      });
    },
  },
};
</script>

<style scoped>
</style>
